import { Lockup } from '@superhi/design'

import { Wrapper } from './styles'

const Error404 = () => (
  <Wrapper>
    <Lockup title="404" subtitle="This page could not be found." />
  </Wrapper>
)

export default Error404
